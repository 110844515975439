import React, { useState, useCallback } from 'react';
import { Tree, Button, message, Input, Spin, Layout, Typography, Switch, Space,Modal } from 'antd';
import { 
  PlusOutlined, 
  LoadingOutlined, 
  MinusOutlined, 
  PlusSquareOutlined,
  UndoOutlined, 
  MenuOutlined ,
  
} from '@ant-design/icons';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { toHaveStyle } from '@testing-library/jest-dom/matchers';
import cakeImage from './cake.png';

const { Header, Content } = Layout;
const { Title } = Typography;
const { TextArea } = Input;

const API_BASE_URL = 'https://fast.yeah200.cfd';  // 假设FastAPI后端运行在本地8000端口

const parseData = (data, topic, parentKey = '') => {
  const lines = data.split('\n').filter(line => line.trim() !== '');
  const root = { title: topic, children: [], level: 0, key: 'root' };
  const stack = [root];
  let keyCounter = 0;

  lines.forEach(line => {
    const match = line.match(/^#+/);
    if (!match) return;
    const level = match[0].length;
    const title = line.replace(/^#+\s*/, '');
    const key = `${parentKey}-${keyCounter++}`;
    const node = { title, children: [], level, key };

    while (stack.length > 1 && stack[stack.length - 1].level >= level) {
      stack.pop();
    }

    stack[stack.length - 1].children.push(node);
    stack.push(node);
  });

  return [root];
};

const getKeysToLevel = (nodes, level, currentLevel = 0) => {
  let keys = [];
  nodes.forEach(node => {
    keys.push(node.key);
    if (node.children && currentLevel < level) {
      keys = keys.concat(getKeysToLevel(node.children, level, currentLevel + 1));
    }
  });
  return keys;
};

const getParentPath = (tree, key, path = []) => {
  for (let node of tree) {
    if (node.key === key) {
      return path.concat(node.title);
    }
    if (node.children) {
      const result = getParentPath(node.children, key, path.concat(node.title));
      if (result) return result;
    }
  }
  return null;
};

const AppHeader = ({ isDarkMode, setIsDarkMode, zoom, setZoom }) =>{
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };

return (
    
   <>
      <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 20px', background: isDarkMode ? '#1f1f1f' : '#6366f1' }}>
        <Title level={3} style={{ color: 'white', margin: 0 }}>Inspire AI</Title>
        <Button icon={<MenuOutlined />} onClick={showModal}>Surprise Button🍰🎂🧁🥮🍮🍪🍩🍨🍫🍬</Button>
        <Space>
          <Switch
            checked={isDarkMode}
            onChange={setIsDarkMode}
            checkedChildren="🌙"
            unCheckedChildren="☀️"
          />
          <Button icon={<MinusOutlined />} onClick={() => setZoom(prev => Math.max(50, prev - 10))} />
          <span>{zoom}%</span>
          <Button icon={<PlusOutlined />} onClick={() => setZoom(prev => Math.min(150, prev + 10))} />
          <Button icon={<UndoOutlined />} onClick={() => setZoom(100)} />
          <Button icon={<MenuOutlined />} />

          {/* 新增的蛋糕按钮 */}
          
        </Space>
      </Header>

      {/* 用于显示图片的模态框 */}
      <Modal
        title="Powerd by love. Your love make the site better"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <img
          src={cakeImage}  // 替换为你想要显示的蛋糕图片链接
          alt="蛋糕"
          style={{ width: '100%' }}
        />
      </Modal>
      </>
  );
};

const InputSection = ({ inputText, setInputText, handleInputSubmit, isLoading }) => (
  <Input.Group compact style={{ display: 'flex', marginBottom: '20px' }}>
    <TextArea
      
      value={inputText}
      onChange={(e) => setInputText(e.target.value)}
      placeholder="Enter your topic or subject"
      autoSize={{ minRows: 1, maxRows: 1 }}
    />
    <Button type="primary" onClick={handleInputSubmit} loading={isLoading} style={{ marginLeft: '10px' }}>
      Get Outline
    </Button>
  </Input.Group>
);

const ResponseSection = ({ llmResponse, setLlmResponse, handleRenderTree }) => (
  <div style={{ marginTop: '20px' }}>
    <TextArea
      rows={10}
      value={llmResponse}
      onChange={(e) => setLlmResponse(e.target.value)}
    />
    <Button onClick={handleRenderTree} style={{ marginTop: '10px' }}>
      Render Tree
    </Button>
  </div>
);

const TreeSection = ({ treeData, expandedKeys, onExpand, zoom, renderTreeNodes }) => (
  <Tree
    style={{ marginTop: '20px', fontSize: `${zoom}%` }}
    expandedKeys={expandedKeys}
    onExpand={onExpand}
    showLine
    showIcon={false}
  >
    {renderTreeNodes(treeData)}
  </Tree>
);

const Test = () => {
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [loadingKeys, setLoadingKeys] = useState([]);
  const [inputText, setInputText] = useState('');
  const [llmResponse, setLlmResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTreeLoading, setIsTreeLoading] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [zoom, setZoom] = useState(100);

  const onExpand = useCallback((expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  }, []);

  const updateTreeData = useCallback((list, key, newNode) => {
    return list.map(node => {
      if (node.key === key) {
        return { ...node, children: [...node.children, newNode] };
      }
      if (node.children) {
        return { ...node, children: updateTreeData(node.children, key, newNode) };
      }
      return node;
    });
  }, []);

  const onLoadData = useCallback(async (node) => {
    if (node.children.some(child => child.isMarkdownContent)) {
      return;
    }
    
    setLoadingKeys(prev => [...prev, node.key]);
    try {
      const fullPath = getParentPath(treeData, node.key);
      const path = fullPath.slice(-5);
      if (!path) {
        message.error('Unable to get node path');
        return;
      }

      const response = await axios.post(`${API_BASE_URL}/get_explanation`, { path });
      const apiResponse = response.data.explanation;
      
      const newNode = {
        title: 'Detailed Explanation',
        key: `${node.key}-explanation`,
        isLeaf: true,
        isMarkdownContent: true,
        content: apiResponse
      };

      setTreeData(prevData => updateTreeData(prevData, node.key, newNode));
      setExpandedKeys(prev => [...prev, node.key]);
    } catch (error) {
      console.error(error);
      message.error('Too hot, please try again');
    } finally {
      setLoadingKeys(prev => prev.filter(key => key !== node.key));
    }
  }, [treeData, updateTreeData]);

  const handleExpandNode = useCallback(async (node) => {
    setLoadingKeys(prev => [...prev, node.key]);
    try {
      const path = getParentPath(treeData, node.key);
      if (!path) {
        message.error('Unable to get node path');
        return;
      }

      const response = await axios.post(`${API_BASE_URL}/expand_node`, { path });
      const apiResponse = response.data.expansion;
      const newNodes = parseData(apiResponse, path[path.length - 1], node.key);

      setTreeData(prevData => {
        const updatedData = [...prevData];
        const updateNode = (nodes) => {
          for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].key === node.key) {
              nodes[i].children = [...(nodes[i].children || []), ...newNodes[0].children];
              return true;
            }
            if (nodes[i].children && updateNode(nodes[i].children)) {
              return true;
            }
          }
          return false;
        };
        updateNode(updatedData);
        return updatedData;
      });

      setExpandedKeys(prev => [...prev, node.key]);
      message.success('Node expanded successfully');
    } catch (error) {
      console.error(error);
      message.error('Failed to expand node');
    } finally {
      setLoadingKeys(prev => prev.filter(key => key !== node.key));
    }
  }, [treeData]);

  const renderTreeNodes = useCallback((data) =>
    data.map((item) => {
      if (item.isMarkdownContent) {
        return (
          <Tree.TreeNode
            {...item}
            title={
              <div style={{ maxHeight: '300px', overflowY: 'auto', border: '1px solid #d9d9d9', borderRadius: '4px', padding: '8px' }}>
                <ReactMarkdown
                  children={item.content}
                  components={{
                    code({node, inline, className, children, ...props}) {
                      const match = /language-(\w+)/.exec(className || '')
                      return !inline && match ? (
                        <SyntaxHighlighter
                          children={String(children).replace(/\n$/, '')}
                          style={tomorrow}
                          language={match[1]}
                          PreTag="div"
                          {...props}
                        />
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      )
                    }
                  }}
                />
              </div>
            }
          />
        );
      }

      return (
        <Tree.TreeNode
          {...item}
          title={
            <span>
              {item.title}
              {!item.isMarkdownContent && item.level !== 0 && (
                <>
                  {loadingKeys.includes(item.key) ? (
                    <LoadingOutlined style={{ marginLeft: '10px' }} />
                  ) : (
                    <>
                      <Button
                        type="link"
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          onLoadData(item);
                        }}
                        style={{ 
                          marginLeft: '10px', 
                          padding: '0 5px',
                          minWidth: '24px',
                          height: '24px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        disabled={loadingKeys.includes(item.key)}
                      >
                        M
                      </Button>
                      <Button
                        type="link"
                        size="small"
                        icon={<PlusSquareOutlined />}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleExpandNode(item);
                        }}
                        style={{ marginLeft: '5px' }}
                        disabled={loadingKeys.includes(item.key)}
                      />
                    </>
                  )}
                </>
              )}
            </span>
          }
        >
          {item.children && renderTreeNodes(item.children)}
        </Tree.TreeNode>
      );
    }), [loadingKeys, onLoadData, handleExpandNode]);

  const handleInputSubmit = useCallback(async () => {
    if (!inputText.trim()) {
      message.warning('Please enter a topic or subject');
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/generate_outline`, { text: inputText });
      setLlmResponse(response.data.outline);
    } catch (error) {
      message.error('Failed to get LLM response');
    } finally {
      setIsLoading(false);
    }
  }, [inputText]);

  const handleRenderTree = useCallback(() => {
    setIsTreeLoading(true);
    setTimeout(() => {
      const newTreeData = parseData(llmResponse, inputText);
      setTreeData(newTreeData);
      setExpandedKeys(getKeysToLevel(newTreeData, 1));
      setIsTreeLoading(false);
    }, 0);
  }, [llmResponse, inputText]);

  return (
    <Layout style={{ minHeight: '80vh'}}>
      <AppHeader isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} zoom={zoom} setZoom={setZoom} />
      <Content style={{ 
        padding: '20px', 
        background: isDarkMode ? '#121212' : '#f0f2f5',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <div style={{ 
          width: '100%', 
          maxWidth: '800px',
          background: isDarkMode ? '#1f1f1f' : 'white', 
          padding: '20px', 
          borderRadius: '8px', 
          boxShadow: '0 1px 2px rgba(0,0,0,0.05)'
        }}>
          <InputSection 
            inputText={inputText} 
            setInputText={setInputText} 
            handleInputSubmit={handleInputSubmit} 
            isLoading={isLoading} 
          />
          {llmResponse && (
            <ResponseSection 
              llmResponse={llmResponse} 
              setLlmResponse={setLlmResponse} 
              handleRenderTree={handleRenderTree} 
            />
          )}
          {isTreeLoading ? (
            <Spin tip="Rendering tree..." style={{ marginTop: '20px' }} />
          ) : (
            treeData.length > 0 && (
              <TreeSection 
                treeData={treeData}
                expandedKeys={expandedKeys}
                onExpand={onExpand}
                zoom={zoom}
                renderTreeNodes={renderTreeNodes}
              />
            )
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default Test;
